/* eslint-disable no-undef */
<template>
  <div>
    <bfc-bar></bfc-bar>
    <doctor-menu></doctor-menu>
    <v-divider></v-divider>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>
    <v-divider></v-divider>
    <v-card>
      <v-card-text>
        <payment-form
          v-if="caseLoaded"
          v-bind:caseInfo="caseInfo"
          v-bind:doctor="doctor"
          @savePayment="savePayment"
        ></payment-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Payment from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import { nullOrUndefined } from "../../../../plugins/libs.js";
import DoctorMenu from "../menubar/menubar";
import axios from "axios";
export default {
  components: {
    "payment-form": Payment,
    "bfc-bar": SystemBar,
    "doctor-menu": DoctorMenu,
  },
  data() {
    return {
      caseLoaded: false,
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { path: "/doctor/dashboard" },
        },
        {
          text: "Check out",
          disabled: true,
          to: { path: "/doctor/checkout" },
        },
      ],
      caseInfo: {},
      doctor: {},
      influencer: 0,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      omisePublicKey: process.env.VUE_APP_OMISE_PUBLIC_KEY,
      OmiseCard: "",
      domain: process.env.VUE_APP_DOMAIN,
      exchangeRate: 1,
    };
  },
  created() {
    const omiseExists = document.getElementById("omiseScript");
    if (!omiseExists) {
      const omiseScript = document.createElement("script");
      omiseScript.setAttribute("src", "https://cdn.omise.co/omise.js");
      omiseScript.setAttribute("id", "omiseScript");
      document.head.appendChild(omiseScript);
    }

    // eslint-disable-next-line no-undef
    //this.OmiseCard = OmiseCard;
    // eslint-disable-next-line no-undef
  },
  computed: {
    ...mapGetters({
      //getCaseInfo: "BFC/getCaseInfo",
      getDoctorInfo: "BFC/getDoctorInfo",
    }),
    currency() {
      return localStorage.getItem("CURRENCY");
    },
  },
  mounted() {
    //this.doctor = { doctor: this.$cookies.get("Profile").doctor };
    this.doctor = {
      doctor: JSON.parse(localStorage.getItem("Profile")).doctor,
    };
    this.influencer = this.doctor.doctor[0].influencer;

    //this.exchangeRate = localStorage.getItem("exchange_rate");
    console.log("mounted checkout");
    console.log(this.doctor);
    this.breadcrumbs.push({
      text: "Case : " + this.$route.params.caseNo,
      disabled: true,
      href: "#",
    });
    console.log("checkout loaded");
    this.caseLoaded = false;
    this.getCaseInfo();

    //console.log(this.caseInfo);
  },
  methods: {
    ...mapActions({
      //getCaseInfo: "BFC/getCaseInfo",
    }),
    async getCaseInfo() {
      //await this.info();
      console.log(this.doctor);
      try {
        let payload = {
          caseNo: this.$route.params.caseNo,
          doctorId: this.doctor.doctor[0]._id,
          selectField:
            "caseNo sequence caseStatus package caseDetail.patient_name doctorId custId package caseStatus",
        };
        console.log(payload);

        await axios
          .post(
            this.url + "/api/bfc/v1/case/caseInfo",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response.data.data);
            if (response.data.statusCode == 0) {
              this.caseInfo = response.data.data[0];

              this.caseLoaded = true;

              //});
              //this.addNewCase(newCase[0]);
              //this.$router.push("/doctor/dashboard");
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log(this.caseInfo);
      } catch (e) {
        console.log(e);
      }
    },
    // async info() {
    //   this.doctor = await this.getDoctorInfo;
    //   console.log(this.doctor);
    // },
    async savePayment(_payment) {
      console.log(_payment);
      let isPackageDefined = "";
      if (typeof this.caseInfo.package !== "undefined") {
        isPackageDefined = this.caseInfo.package.value;
      }
      let packageValue = isPackageDefined;
      let stateStatus = "Submitted";
      let casePackage = {
        text: "",
        value: "",
        price: 0,
        packageStatus: "None",
      };
      let packageStatus = "Pending";
      if (typeof packageValue !== "undefined") {
        if (this.doctor.doctor[0].vip == 1) {
          packageStatus = "Paid";
        }
        if (packageValue != "") {
          this.caseInfo.package.packageStatus = packageStatus;
          stateStatus = "Approved";
          casePackage = this.caseInfo.package;
        }
      }
      console.log("savePayment");
      console.log(this.doctor);
      console.log("CHECKOUT");
      let payload = {
        caseId: this.caseInfo._id,
        doctorId: this.doctor.doctor[0]._id,
        custId: this.custId,
        caseNo: _payment.caseNo,
        case: {
          caseStatus: {
            stateStatus: stateStatus,
          },
          package: casePackage,
          currentStatus: this.caseInfo.caseStatus.stateStatus,
        },
        casePayment: {
          //currency: this.currency,
          caseId: this.caseId,
          memberId: this.memberId,
          custId: this.custId,
          doctorId: this.doctor.doctor[0]._id,

          payment: {
            payment_type: _payment.payment_type,
            type: _payment.paymentType,
            description: _payment.description,
            amount: _payment.amount,
            price: _payment.price,
            discount: _payment.discount,
            paymentDate: new Date().toISOString(),
            paymentAgent: "Omise",
            paymentStatus: packageStatus,
            influencer: this.influencer,
          },
          omise: _payment.omise,
          return_uri: this.domain + "/doctor/payment-status/" + _payment.caseNo,
          vip: this.doctor.doctor[0].vip,
          currency: localStorage.getItem("CURRENCY") == "USD" ? "USD" : "THB",
          description: _payment.description,
          paymentStatus: packageStatus,
        },
      };

      try {
        let paymentUrl = "/api/bfc/v1/doctor/updatePayment";
        if (this.doctor.doctor[0].vip == 1 || this.influencer == 1) {
          paymentUrl = "/api/bfc/v1/payment/vipPayment";
        }
        //let paymentResponse = [];
        axios
          .post(
            this.url + paymentUrl,
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log("---------update payment response------------");
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //let newCase;
              //newCase.push(response.data.data);
              //console.log(typeof newCase[0]);
              // paymentResponse = [response.data.data.case].map((item) => {
              //   //console.log(item);
              //   return {
              //     caseNo: item.caseNo,
              //     patient: nullOrUndefined(item.caseDetail.patient_name),
              //     process: "",
              //     aligner_wear_log: "",
              //     gender: nullOrUndefined(item.caseDetail.gender),
              //     race: nullOrUndefined(item.caseDetail.race),
              //     birthdate: nullOrUndefined(item.caseDetail.birthdate),
              //     mobile: nullOrUndefined(item.caseDetail.mobile),
              //     actions: item._id,
              //     caseStatus: item.caseStatus,
              //   };
              // });
              //console.log(paymentResponse);
              if (stateStatus == "Submitted") {
                //this.addNewCase(paymentResponse[0]);
              }
              if (
                (this.doctor.doctor[0].vip != 1 ||
                  this.doctor.doctor[0].vip == null) &&
                this.influencer != 1
              ) {
                if ( response.data.payment_type != "promptpay") {
                  //
                  window.location.href = response.data.omiseData.authorize_uri;
                } else {
                  //alert("Payment Error!");
                 
                    if(response.data.payment_type == "promptpay"){
                      this.$router.push("/doctor/payment-status/" + _payment.caseNo);
                    }
                  
                }
              } else {
                this.$router.push("/doctor/payment-status/" + _payment.caseNo);
              }
              //alert("Saved");
              //if(paymentResponse.)
              //this.$router.push("/doctor/dashboard");
            } else {
              // show error
              alert("Error " + response.error);
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
};
</script>
